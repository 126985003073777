import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Skeleton from "react-loading-skeleton";
import Lightbox from 'react-image-lightbox';
import Video from '../common/Video';
import 'react-image-lightbox/style.css';
import ExampleSites from '../common/ExampleSites';
import FeatureAccordion from '../common/FeatureAccordion';
import BulletPoints from '../common/BulletPoints';

const SIDE_PADDING = 20;
const CONTENT_WIDTH = 1000 - (2 * SIDE_PADDING);

const MainContent = ({ data, resizing }) => {
  const [screenshotLoaded, setScreenshotLoaded] = useState(false);
  return (
    <MainContainer>
      <ScreenshotContainer>
        <Screenshot src='./images/main_screenshot.png' />
        <WireframeExampleLabel>(Wireframe example)</WireframeExampleLabel>
      </ScreenshotContainer>
      <SectionContainer>
        <BulletPoints />
      </SectionContainer>


      {/* <SectionContainer>
        <SectionLabel>
          Features
        </SectionLabel>
        <FeatureAccordion />
      </SectionContainer> */}


      {/* <SectionContainer>
        <SectionLabel>
          Example Sites
        </SectionLabel>
        <ExampleSites />
      </SectionContainer> */}


    </MainContainer>
  )
}

const MainContainer = styled.div`
  background: #FFF;
  margin-top: -5px;

  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: ${SIDE_PADDING}px;
  padding-right: ${SIDE_PADDING}px;
`

const ScreenshotContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Screenshot = styled.img`
  width: 100%;
`

const WireframeExampleLabel = styled.span`
  font-size: 11pt;
  line-height: 0;
  color: #0D2142;
  margin-top: 10px;
`

const SectionContainer = styled.div`
  width: 100%;

  @media print {
    &:last-child {
      // margin-top: 2em;
    }
  }
`

const SectionLabel = styled.div`
  font-size: 24pt;
  font-weight: 600;
  margin-bottom: 10px;
  color: #0D2142;
`

const SkeletonContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`

export default MainContent;