import React from 'react';
import styled from 'styled-components';
import headerBg from './desktop/headerBg.svg';
import DownloadBrochureButton from './common/DownloadBrochureButton';

const CONTENT_WIDTH = 1000;
const LOGO_HEIGHT = 45;

const Layout = ({ 
  children,
  title,
  description,
  pdfBrochureUrl,
  contactDetails,
}) => {
  return (<>
    <HeaderWrapper>
      <HeaderContent>
        <DatacomLogo />
        {pdfBrochureUrl && (
          <DownloadBrochureButton
            {...{ pdfBrochureUrl }}
            fontSize={'10pt'}
            iconSize={1.4}
            height={LOGO_HEIGHT}
          />
        )}
        <Title><span>{title}</span><ConceptLabel>(Concept)</ConceptLabel></Title>
        <Description>{description}</Description>
        {/* {contactDetails && (
          <ContactDetailsContainer>
            <ContactLabel>Contact</ContactLabel>
            <span style={{ marginTop: 5 }}><strong>{contactDetails.name}</strong></span>
            <span><strong>{contactDetails.jobTitle}</strong></span>
            <ClickableContactItem href={`mailto:${contactDetails.email}`} style={{ marginTop: 5 }}><u>{contactDetails.email}</u></ClickableContactItem>
            <span><b>mobile:</b> <ClickableContactItem href={`tel:${contactDetails.mobile}`} ><u>{contactDetails.mobile}</u></ClickableContactItem></span>
            <span><b>ddi:</b> <ClickableContactItem href={`tel:${contactDetails.ddi}`} ><u>{contactDetails.ddi}</u></ClickableContactItem></span>
            <span>{contactDetails.address}</span>
          </ContactDetailsContainer>
        )} */}
      </HeaderContent>
    </HeaderWrapper>
    <ContentWrapper>
      {children}
    </ContentWrapper>
  </>)
}

const HeaderWrapper = styled.div`
  width: 100%;
  background-color: default;
  background-image: url(${headerBg});
  background-position: bottom;

  padding-left: 1em;
  padding-right: 1em;
  @media print {
    padding: 1.4em;
  }

  padding-bottom: 60px;
  @media only screen and (max-width: 768px) {
    padding-bottom: 70px;
  }

  background-origin: border-box;
  background-size: cover;

  background-blend-mode: screen;

  @media print {
    -webkit-print-color-adjust: exact;
    background-color: #0D2142;
    background-blend-mode: screen;
  }
`;

const ContentWrapper = styled.div`
  max-width: ${CONTENT_WIDTH}px;
  margin: 0 auto;  
`;

const ConceptLabel = styled.span`
  font-size: 11pt;
  line-height: 0;
  margin-top: 15px;
  margin-bottom: 10px;

  @media print {
    margin-top: 10px;
  }
`

const Description = styled.div`
  grid-area: desc;
  font-size: 14pt;
  font-weight: 400;
  // max-width: 300px;
  margin: 0 auto;

  @media print {
    // max-width: unset;
    margin: unset;
    margin-top: -10px;
    margin-bottom: 20px;
  }
`;

const ContactDetailsContainer = styled.div`
  grid-area: contact;
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 10pt;
  margin-left: auto;
`;

const Title = styled.div`
  grid-area: title;
  font-size: 22pt;
  font-weight: 600;

  max-width: 350px;
  

  display: flex;
  flex-direction: column;

  @media print {
    max-width: unset;
    line-height: ${LOGO_HEIGHT}px;
    margin-top: -12px;
  }
`;

const HeaderContent = styled(ContentWrapper)`
  display: grid;
  grid-template-areas:  "logo logo pdf"
                        "title desc pdf";
  @media only screen and (max-width: 768px) {
    grid-template-areas:  "logo pdf"
                          "title title"
                          "desc desc";
    // ${Description} {
    //   max-width: unset;
    // }
    ${ContactDetailsContainer} {
      text-align: right;
    }
    ${Title} {
      font-size: 17.75pt;
    }
  }
  @media print {
    grid-template-areas:  "logo title title"
                        "desc desc contact";
  }
  
  color: #fff;
  grid-gap: 1em;
  
  ${DownloadBrochureButton} {
    grid-area: pdf;
    width: min-content;
    white-space: nowrap;
    margin-left: auto;

    @media print {
      display: none;
    }    
  }
`;

const DatacomLogo = styled(
  ({ className }) => {
    return (
      <a href="https://www.datacom.co.nz/Home.aspx" {...{ className }}>
        <img alt='Datacom' src="/datacom_logo.png" />
      </a>
    );
  }
)`
  grid-area: logo;
  height: ${LOGO_HEIGHT}px;
  width: min-content;

  img {
    height: 100%;
  }
`;

const ContactLabel = styled.div`
  font-size: 11pt;
  line-height: 1;
`;

const ClickableContactItem = styled.a`
  text-decoration: none;
  color: white;
`;

export default Layout;