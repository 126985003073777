import React from 'react';
import styled from 'styled-components';
import data from '../../data';

const BulletPoints = () => {
  const { bulletPointInfo } = data;
  return (
    <MainContainer>
      {bulletPointInfo.map(item => (
        <Card key={item.title}>
          <Title>{item.title}</Title>
          <ul>
            {item.bulletPoints.map(bulletPoint => (
              <BulletPoint key={bulletPoint}>
                {bulletPoint}
              </BulletPoint>
            ))}
          </ul>
        </Card>
      ))}
    </MainContainer>
  );
}

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

const Card = styled.div`
  // flex: 1;
  margin: 20px;
  @media print {
    margin: 0;
    margin-top: 30px;
    margin-right: 15px;
  }
`

const Title = styled.div`
  color: #0D2142;
  font-size: 14pt;
  font-weight: 600;
  // line-height: 12pt;
`

const BulletPoint = styled.li`
  color: #0D2142;
  font-size: 12pt;
`

export default BulletPoints;