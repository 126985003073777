import React from 'react';
import styled from 'styled-components';
import data from '../../data';

const ExampleSites = () => {
  console.log(data.exampleSites);
  return (
    <MainContainer>
      {data.exampleSites.map(site => <Site key={site.name} site={site} />)}
    </MainContainer>
  );
}

const Site = ({ site }) => {
  return (
    <Card>
      <ThumbnailContainer src='/images/thumbnail.jpg' />
      <Text>{site.name}</Text>
      <Text>{site.url}</Text>
    </Card>
  )
};

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;


  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
`

const Text = styled.div`
  color: #0D2142;
  font-size: 10pt;
  font-weight: 500;
  line-height: 1.4;
`

const Card = styled.div`
  @media print {
    -webkit-print-color-adjust: exact;
  }
`

const ThumbnailContainer = styled.div`
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

const Thumbnail = styled.img`
  width: 100%;
`

export default ExampleSites;