import React from 'react'
import { hydrate, render } from "react-dom";
import 'modern-normalize/modern-normalize.css'
import './globalStyles.css'
import App from './App'
import registerServiceWorker, { unregister } from './registerServiceWorker'

const rootEl = document.getElementById('root');
if (rootEl.hasChildNodes()) {
  hydrate(<App />, rootEl);
} else {
  render(<App />, rootEl);
}

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    render(<NextApp />, rootEl)
  })
}

if (process.env.REACT_APP_SITE_URL && 'localStorage' in window) {
  window.localStorage.setItem('netlifySiteURL', process.env.REACT_APP_SITE_URL)
}
