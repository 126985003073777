export default {
  title: 'Event Response Management System',
  description: 'Providing real-time analytics & response tools for multi agency event management.',
  pdfBrochureUrl: '/datacom-ERMS.pdf',
  contactDetails: {
    name: 'Mike Bullock',
    jobTitle: 'Senior General Manager Sales',
    email: 'mikeb@datacom.co.nz',
    mobile: '+64274496200',
    ddi: '+6449796826',
    address: '68 Jervois Quay, Wellington',
  },
  exampleSites: [
    {
      name: '(Datacom) MCDEM',
      thumbnail: './images/thumbnails',
      url: 'URL HERE',
      bulletPoints: ['bullet point 1', 'bullet point 2']
    },
    {
      name: 'Disaster Response',
      thumbnail: './images/thumbnails',
      url: 'URL HERE',
      bulletPoints: ['bullet point 1', 'bullet point 2']
    },
    {
      name: 'Disaster Response',
      thumbnail: './images/thumbnails',
      url: 'URL HERE',
      bulletPoints: ['bullet point 1', 'bullet point 2']
    },
    {
      name: 'Disaster Response',
      thumbnail: './images/thumbnails',
      url: 'URL HERE',
      bulletPoints: ['bullet point 1', 'bullet point 2']
    },
    {
      name: 'Disaster Response',
      thumbnail: './images/thumbnails',
      url: 'URL HERE',
      bulletPoints: ['bullet point 1', 'bullet point 2']
    },
    {
      name: 'Disaster Response',
      thumbnail: './images/thumbnails',
      url: 'URL HERE',
      bulletPoints: ['bullet point 1', 'bullet point 2']
    }
  ],
  bulletPointInfo: [
    {
      title: 'Public viewable information',
      bulletPoints: [
        'Real time aggregated information feeds',
        'Fact checked social media threads',
        'Live chat for public users',
        'News feeds searchable via regions',
        'Event timelines',
        'Health statistics and symptoms checklist '
      ]
    },
    {
      title: 'Shared collaborative areas for staff ',
      bulletPoints: [
        'Document repository',
        'Email',
        'Policy and statistics',
        'Event analytics',
        'Questions from the public',
      ]
    }
  ],
}
