import React from 'react';
import styled from 'styled-components';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

const FeatureAccordion = ({ title, description }) => (
  <MainContainer>
    <Accordion allowMultipleExpanded allowZeroExpanded>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <ContentText>
            Exercitation in fugiat est ut ad ea cupidatat ut in
            cupidatat occaecat ut occaecat consequat est minim minim
            esse tempor laborum consequat esse adipisicing eu
            reprehenderit enim.
          </ContentText>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Aenean luctus mi ut dolor imperdiet posuere.
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <ContentText>
            In ad velit in ex nostrud dolore cupidatat consectetur
            ea in ut nostrud velit in irure cillum tempor laboris
            sed adipisicing eu esse duis nulla non.
          </ContentText>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  </MainContainer>
);

const MainContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;

  @media print {
    .accordion__button {
      -webkit-print-color-adjust: exact;
    }
    .accordion__panel {
      display: block;
      animation: unset;
    }
  }
`

const ContentText = styled.p`
  color: #0D2142;
`

export default FeatureAccordion;