import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import DemoPage from './components/DemoPage';
import Layout from './components/Layout';
import ServiceWorkerNotifications from './components/ServiceWorkerNotifications'

class App extends Component {
  render() {
    return (
      <Router>
        <>
          <ServiceWorkerNotifications reloadOnUpdate />
          <Switch>
            <Route
              path={'/'}
              exact
              render={() => <DemoPage />}
            />
            <Redirect from="*" to="/" />
          </Switch>
        </>
      </Router>
    )
  }
}

export default App
