import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import prettyBytes from 'pretty-bytes';
import Icon from '@mdi/react';
import { mdiFilePdfOutline } from '@mdi/js';

// Get the file size of the PDF via the http head of the file URL.
const getFileSize = url => {
  var fileSize = null;
  var http = new XMLHttpRequest();
  http.open('HEAD', url, false); // false = Synchronous
  http.send(null);
  if (http.status === 200) {
    fileSize = http.getResponseHeader('content-length');
  }
  return fileSize;
};

const StyledIcon = styled(Icon)`
  margin-left: auto;
  margin-right: 5px;
`;

const DownloadBrochureButton = styled(
  ({ pdfBrochureUrl, height, iconSize, className }) => {
    const [fileSize, setFileSize] = useState(0);
    useEffect(() => {
      setFileSize(getFileSize(pdfBrochureUrl));
    }, []);
    return (
      <a href={pdfBrochureUrl} download height={height} {...{ className }}>
        <StyledIcon
          path={mdiFilePdfOutline}
          size={iconSize}
          color="white"
        />
        <span>
          Download <span>page as</span> PDF
          <span>
            {(fileSize) ? ` (${prettyBytes(Number(fileSize))})` : ''}
          </span>
        </span>
      </a>
    )
  }
)`
  min-width: 200px;
  height: ${({ height }) => height}px;
  
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
    -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;

  background-color: #216626;
  transition: background-color .35s ease-in-out;
  &:hover {
      background-color: #184d1c;
  }

  padding-left: 20px;
  padding-right: 20px;

  @media print {
    display: none;
  }

  ${StyledIcon} {
    margin-right: 10px;
    text-align: center;
  }
  & > span {
    color: white;
    margin-right: auto;
  }
  @media only screen and (max-width: 768px) {
    & {
      min-width: unset;
    }
    & > span > span {
      display: none;
    }
  }
`;

export default DownloadBrochureButton;