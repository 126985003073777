import React from 'react';
import styled from 'styled-components';
import Skeleton from "react-loading-skeleton";
import YouTube from 'react-youtube';

const calculateVideoSize = width => ({
  width: width,
  height: width / (16 / 9)
});

const Video = ({ videoId, width, resizing }) => {

  const videoSize = calculateVideoSize(width);

  const videoOptions = {
    width: videoSize.width,
    height: videoSize.height,
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
      rel: 0,
      ecver: 2
    }
  };

  return (
    <MainContainer height={videoSize.height}>
      <VideoContainer width={videoSize.width} height={videoSize.height} >
        {!resizing && (
          <YouTube
            videoId={videoId}
            opts={videoOptions}
          />
        )}
      </VideoContainer>
      <VideoSkeleton width={videoSize.width} height={videoSize.height} />
    </MainContainer>
  )
};

const MainContainer = styled.div`
  width: 100%;
  max-width: 100%;
  height: ${({ height }) => height}px;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
`

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`

const VideoSkeleton = styled(Skeleton)`
  border-radius 5px;
`

export default Video;