import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Layout from './Layout';
import MainContent from './desktop/MainContent';
import data from '../data';

const DemoPage = () => {
  const { title, description, pdfBrochureUrl, contactDetails, ...contentProps } = data;
  const headerProps = { title, description, pdfBrochureUrl, contactDetails };
  
  return (
    <Layout {...headerProps}>
      <MainContent {...contentProps} />
    </Layout>
  );
};

export default DemoPage;